p {
  line-height: 1.5rem;
}
.navbarMenu {
  cursor: pointer;
  padding: 2px 0;
  width: 100% !important;
}
.cursorTospan {
  cursor: pointer !important;
}

.link {
  color: #2c7be5;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
}

.link:hover {
  color: #1657af;
  text-decoration: none;
}

.dropdownOpen {
  position: absolute !important;
  transform: translate3d(-283px, 29px, 0px) !important;
  top: 0px !important;
  left: 0px !important;
  will-change: transform !important;
}
.acp-content ul {
  padding-left: 0;
}

.acp-content ul li {
  display: inline;
}

.n95 img {
  width: auto;
  max-height: 200px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.market-agg-radio-list {
  max-height: 200px;
  overflow-y: auto;
}

.market-agg-card {
  height: fit-content;
}

.supply-label {
  padding: 4px 0 10px 30px;
  display: block;
  color: green;
  font-size: 0.9rem;
}

.supply-label-usa {
  background: url(/icon/usa.png) 2px 3px no-repeat;
  background-size: 26px;
  color: #007cba;
}

.supply-label-verified {
  background: url(/icon/delivery.png) 2px 4px no-repeat;
  background-size: 22px;
}

.supply-label-reference {
  background: url(/icon/reference.png) 2px 4px no-repeat;
  background-size: 22px;
  color: #333;
}

.supply-label-fda {
  background: url(/icon/fda.png) 2px 3px no-repeat;
  background-size: 26px;
  color: #007cba;
}

.supply-label-cdc {
  background: url(/icon/cdc.png) 2px 3px no-repeat;
  background-size: 26px;
  color: #006efc;
}

.form-asterik {
  color: red;
}

.agg-header {
  min-height: 283px;
}

.product-image {
  max-height: 333px !important;
  min-height: 333px !important;
  object-fit: contain;
}
.product-default-image {
  max-height: 333px !important;
  min-height: 333px !important;
  object-fit: cover !important;
}
.product-card {
  max-height: 53em !important;
  min-height: 53em !important;
}

@media (max-width: 1100px), (max-height: 950px) {
  .product-card {
    max-height: 56em !important;
    min-height: 56em !important;
  }
}

.permission-check {
  height: 1em !important;
  width: 1em !important;
}

.product-details-image {
  max-height: 150px !important;
}

.productImages {
  height: 5em;
  width: 5em;
}
.market-carousel {
  width: 100%;
  max-height: 100%;
  height: 100%;
}

.carousel-inner {
  max-height: 100%;
  height: 100%;
  max-width: auto;
}

.carousel-item {
  max-height: 100%;
}

.carousel-item-agg {
  max-height: 250px;
  min-height: 250px;
  margin-left: auto;
  margin-right: auto;
}

img {
  max-width: 100%;
  width: auto;
  height: auto;
}

.market-carousel-agg {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
}

@media (max-width: 800px), (max-height: 550px) {
  .agg {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}

@media (max-width: 765px) {
  .sm-btn-w-75 {
    width: 75% !important;
  }
}

@media (max-width: 576px) {
  .xs-padding {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .xs-pb-zero {
    padding-bottom: 0px;
  }

  .xs-btn-w-75 {
    width: 75% !important;
  }
}

.carousel-control-next,
.carousel-control-prev {
  filter: invert(100%);
}

.main-bg-color {
  background-color: #f9fbfd;
}

.mark-span {
  width: 7em;
  background-color: #edecea;
  font-size: 16px;
}
.span-font {
  font-size: 16px !important;
}
.carousel-control-next,
.carousel-control-prev {
  filter: invert(100%);
}
.card-header {
  padding-bottom: 1.5rem;
}
.card-header-height {
  height: min-content !important;
}
.main-bg-color {
  background-color: #f9fbfd !important;
}
.button-margin {
  margin-bottom: 2em !important;
}
.navFont {
  font-size: 17px !important;
}
.cursorDefault {
  cursor: default;
}
.pointerClass {
  cursor: pointer;
}
.nav-icon-hover {
  opacity: 0.5;
}
.nav-icon-hover:hover {
  opacity: 1;
}
.active {
  opacity: 1;
}
.align-image {
  padding-bottom: 4px !important;
}
@media (min-width: 1300px) {
  .ama-carousel > .carousel-inner {
    max-width: 200px !important;
  }
}
.form-button {
  width: 5em !important;
}

.product-detail-image {
  max-height: 150px !important;
  object-fit: contain;
}
.badge-circle {
  border-radius: 50% !important;
}

.toolTip {
  margin: 4em 0;
  text-align: center;
}

/* Tooltip Styles */

/* Add this attribute to the element that needs a tooltip */
[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer;
}

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

/* Position tooltip above the element */
[data-tooltip]:before {
  position: absolute;
  bottom: 150%;
  left: 30%;
  margin-bottom: 5px;
  margin-left: -63px;
  padding: 10px;
  width: auto;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #000;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
}

/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid #000;
  border-top: 5px solid hsla(0, 0%, 20%, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
}

/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
}


.phone-number{
  padding-top: 10px !important;
}

.pn95-button{
    color: #FFFFFF !important;
    border-color: #ff5a46 !important;
    background: #ff5a46 !important;
   font-weight: 500 !important;
}

.pn95-button:hover {
  color: #FFFFFF !important;
  border-color: red !important;
  background: red !important;
}

.bg-ellipses.bg-dark {
  background-image: radial-gradient(#12263f,#12263f 69%,transparent 70%) !important;
}

.titleHeader{
  padding-left: 0.8em !important;
}

.table-dashboard  {
  max-height: 150px;
  }
@media (min-height:710px ){
.table-dashboard  {
  max-height: 200px;
  }
}

@media (min-height:750px ){
  .table-dashboard  {
    max-height: 250px;
    }
  }

  @media (min-height:900px ){
    .table-dashboard  {
      max-height: 400px;
      }
    } 
 
@media (min-height:800px ){
  .table-dashboard  {
    max-height: 300px;
    }
  } 
@media (max-width:1024px){
.table-dashboard {
  max-height:600px;
  overflow-y: hidden;
}
}
thead tr:nth-child(1) th{   
  position: sticky;
  top: 0;
}